<template>
  <default-table-body-row align="center">
    <v-col
      cols="12"
      sm="2"
    >
      <span
        class="text--text font-weight-bold text-truncate text-uppercase"
        :title="sortingBay.nome"
        >{{ sortingBay.nome }}</span
      >
    </v-col>
    <v-col
      cols="4"
      offset="4"
      sm="3"
      offset-sm="0"
    >
      <v-chip
        class="d-block font-weight-bold text-overline"
        :color="`${sortingBay.ativo ? 'green' : 'pink'} white--text`"
      >
        {{ sortingBay.ativo ? "ATIVA" : "INATIVA" }}
      </v-chip>
    </v-col>
    <v-col
      v-if="sortingBay.auditoria"
      cols="4"
      offset="4"
      sm="3"
      offset-sm="0"
    >
      <v-chip
        class="d-block font-weight-bold text-overline"
        :color="`primary white--text`"
      >
        AUDITORIA
      </v-chip>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="12"
      sm="2"
    >
      <default-fab
        :top="false"
        :left="true"
        tooltip-text="Editar Baia"
        @click="openEditBayModal(sortingBay)"
        ><v-icon>mdi-pencil</v-icon></default-fab
      >
    </v-col>
  </default-table-body-row>
</template>

<script>
import DefaultFab from "@/shared/components/vuetify/DefaultFab.vue";
import DefaultTableBodyRow from "../../../shared/components/vuetify/DefaultTableBodyRow.vue";

export default {
  name: "ListingRow",
  components: {
    DefaultFab,
    DefaultTableBodyRow,
  },
  props: {
    sortingBay: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    openEditBayModal(bayToBeEdited) {
      this.$emit("openEditBayModal", bayToBeEdited);
    },
  },
};
</script>

<style scoped></style>
