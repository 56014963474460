<template>
  <div>
    <template v-if="!getIsLoading">
      <template v-if="getSortingBays.length > 0">
        <v-row
          dense
          class="mt-4"
        >
          <v-col
            cols="12"
            md="6"
            xl="4"
            v-for="(sortingBay, index) in getSortingBays"
            :key="index"
          >
            <listing-row
              class="mt-n2"
              :sorting-bay="{ ...sortingBay }"
              @openEditBayModal="openEditBayModal"
            />
          </v-col>
        </v-row>
        <default-pagination
          v-model="getUrlParams.page"
          :length="getPagination.lastPage"
          @input="changePage"
        />
      </template>
    </template>
    <default-spinner v-else />
    <edit-bay
      v-model="isEditBayModalOpen"
      :bay-to-be-edited="bayToBeEdited"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DefaultPagination from "@/shared/components/vuetify/DefaultPagination.vue";
import DefaultSpinner from "@/shared/components/vuetify/DefaultSpinner.vue";
import ListingRow from "./ListingRow.vue";
import EditBay from "./EditBay.vue";

export default {
  name: "TheListing",
  data() {
    return {
      bayToBeEdited: {},
    };
  },
  components: {
    DefaultPagination,
    DefaultSpinner,
    EditBay,
    ListingRow,
  },
  created() {
    this.sortingBays();
  },
  methods: {
    ...mapActions("sortingBays", ["sortingBays", "setIsEditBayModalOpen"]),
    changePage() {
      this.sortingBays();
    },
    openEditBayModal(bayToBeEdited) {
      this.bayToBeEdited = bayToBeEdited;
      this.setIsEditBayModalOpen(true);
    },
  },
  computed: {
    ...mapGetters("sortingBays", [
      "getIsLoading",
      "getPagination",
      "getSortingBays",
      "getUrlParams",
      "getIsEditBayModalOpen",
    ]),
    isEditBayModalOpen: {
      get() {
        return this.getIsEditBayModalOpen;
      },
      set(newValue) {
        this.setIsEditBayModalOpen(newValue);
      },
    },
  },
};
</script>

<style scoped></style>
