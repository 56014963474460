<template>
  <v-dialog
    v-model="dialogModel"
    width="350"
    class="text-center"
  >
    <v-card
      data-vuetify
      class="py-2"
    >
      <v-form
        class="px-2"
        ref="registerBayForm"
      >
        <v-card-actions class="pt-10 pb-0"
          ><default-text-field
            type="number"
            min="1"
            :max="maxNumberOfBays"
            v-model="bayNumber"
            label="Número da Baia"
            :hide-details="false"
            :rules="[rules.required, rules.isValid]"
            :clearable="false"
        /></v-card-actions>
        <v-card-actions class="py-0">
          <default-switch
            v-model="bayToBeRegistered.ativo"
            disabled
          />
          <span class="ml-12">Baia Ativa</span>
        </v-card-actions>
        <v-icon
          style="
            position: absolute !important;
            top: 7px !important;
            right: 7px !important;
          "
          @click="$emit('input', false)"
          >mdi-close</v-icon
        >
        <v-card-actions
          ><default-button
            :loading="isLoading"
            @click="registerBay(bayToBeRegistered)"
            >Cadastrar</default-button
          ></v-card-actions
        >
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultSwitch from "@/shared/components/vuetify/DefaultSwitch.vue";
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";

export default {
  name: "RegisterBay",
  components: {
    DefaultButton,
    DefaultSwitch,
    DefaultTextField,
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
      bayNumber: "",
    };
  },
  props: {
    value: {},
  },
  methods: {
    async registerBay(bayToBeRegistered) {
      this.isLoading = true;
      if (this.isValid) {
        await this.$store.dispatch(
          "sortingBays/registerBay",
          bayToBeRegistered
        );
      }
      this.isLoading = false;
    },
  },
  computed: {
    ...mapState("sortingBays", ["maxNumberOfBays"]),
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    numberOfBays() {
      return this.$store.getters["sortingBays/getSortingBays"].length;
    },
    rules() {
      return {
        required: (input) => !!input || "Campo obrigatório",
        isValid: (input) => {
          return (
            new RegExp(`^[1-${this.maxNumberOfBays}]$`).test(input) ||
            "Digite um número de 1 a " + this.maxNumberOfBays
          );
        },
      };
    },
    isValid() {
      return this.$refs.registerBayForm.validate();
    },
    bayToBeRegistered() {
      return {
        nome: "Baia " + this.bayNumber,
        ativo: true,
        auditoria: false,
      };
    },
  },
};
</script>

<style scoped></style>
