<template>
  <v-container fluid>
    <the-header />
    <the-listing />
  </v-container>
</template>

<script>
import TheHeader from "../components/TheHeader.vue";
import TheListing from "../components/TheListing.vue";

export default {
  name: "SortingBays",
  components: {
    TheHeader,
    TheListing,
  },
};
</script>

<style scoped></style>
