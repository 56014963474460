<template>
  <v-switch
    v-on="$listeners"
    v-bind="$attrs"
    color="primary"
    dense
    style="width: 0"
    :value="inputValue"
    v-model="model"
  ></v-switch>
</template>

<script>
export default {
  name: "DefaultSwitch",
  props: {
    inputValue: {},
    value: {},
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>

<style scoped></style>
