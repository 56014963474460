<template>
  <v-dialog
    v-model="dialogModel"
    width="350"
    class="text-center"
  >
    <v-card
      data-vuetify
      class="py-2"
    >
      <v-card-actions class="pt-10"
        ><default-text-field
          readonly
          disabled
          :label="bayToBeEdited.nome"
          class="text-uppercase"
      /></v-card-actions>
      <v-card-actions class="py-0">
        <default-switch v-model="bayToBeEdited.ativo" />
        <span class="ml-12">Baia Ativa</span>
      </v-card-actions>
      <v-card-actions class="py-0">
        <default-switch v-model="bayToBeEdited.auditoria" />
        <span class="ml-12">Baia de Auditoria</span>
      </v-card-actions>

      <v-icon
        style="
          position: absolute !important;
          top: 7px !important;
          right: 7px !important;
        "
        @click="$emit('input', false)"
        >mdi-close</v-icon
      >
      <v-card-actions
        ><default-button
          :loading="isLoading"
          @click="editBay"
          >Editar</default-button
        ></v-card-actions
      >
    </v-card>
  </v-dialog>
</template>

<script>
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import DefaultSwitch from "@/shared/components/vuetify/DefaultSwitch.vue";
import DefaultTextField from "@/shared/components/vuetify/DefaultTextField.vue";

export default {
  name: "EditBay",
  components: {
    DefaultButton,
    DefaultTextField,
    DefaultSwitch,
  },
  data() {
    return {
      isModalOpen: false,
      isLoading: false,
    };
  },
  props: {
    value: {},
    bayToBeEdited: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    async editBay() {
      this.isLoading = true;
      const bayToBeEdited = {
        id: this.bayToBeEdited.id,
        nome: this.bayToBeEdited.nome,
        auditoria: this.bayToBeEdited.auditoria,
        ativo: this.bayToBeEdited.ativo,
      };
      await this.$store.dispatch("sortingBays/editBay", bayToBeEdited);
      this.isLoading = false;
    },
  },
  computed: {
    dialogModel: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
  },
};
</script>

<style scoped></style>
