<template>
  <v-row class="justify-sm-end">
    <v-col
      cols="12"
      sm="4"
      md="3"
      xl="2"
    >
      <default-button
        @click="openRegisterBayModal"
        :disabled="getIsRegisterBayButtonDisabled"
        >Cadastrar Baia</default-button
      >
    </v-col>
    <register-bay
      v-model="isRegisterBayModalOpen"
      :key="reRender"
    />
  </v-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DefaultButton from "@/shared/components/vuetify/DefaultButton.vue";
import RegisterBay from "./RegisterBay.vue";

export default {
  name: "TheHeader",
  components: {
    DefaultButton,
    RegisterBay,
  },
  data() {
    return {
      reRender: 0,
    };
  },
  computed: {
    ...mapGetters("sortingBays", [
      "getIsRegisterBayButtonDisabled",
      "getSortingBays",
      "getIsRegisterBayModalOpen",
    ]),
    isRegisterBayModalOpen: {
      get() {
        return this.getIsRegisterBayModalOpen;
      },
      set(newValue) {
        this.setIsRegisterBayModalOpen(newValue);
      },
    },
  },
  methods: {
    ...mapActions("sortingBays", ["setIsRegisterBayModalOpen"]),
    openRegisterBayModal() {
      this.setIsRegisterBayModalOpen(true);
      this.reRender += 1;
    },
  },
};
</script>
